import { APP_ID, ASKOMA_APP_ID, CKW_APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID, EZEE_APP_ID, THERMONDO_APP_ID, EKZ_APP_ID } from '../../config';

const getDeviceColumns = (roleType) => [
  { key: 'ip', i18nLabel: 'IP', show: true },
  { key: 'mac', i18nLabel: 'MAC', show: true },
  { key: 'signal', i18nLabel: 'signal', show: true },
  { key: 'errorcodes', i18nLabel: 'error', show: true },
  { key: 'errorDetails', i18nLabel: 'errorDetails', show: !['end_user'].includes(roleType) }
];

const productionAnalysisPortfolioSystemsColumns = [
  { key: 'installedCapacity', i18nLabel: 'installedCapacity', show: true },
  { key: 'zip', i18nLabel: 'zip', show: true },
  { key: 'supportContractFlag', i18nLabel: 'supportContractFlag', show: true },
  { key: 'totalYieldKWh', i18nLabel: 'totalYieldKWh', show: true },
  { key: 'totalPerformanceLossesKWh', i18nLabel: 'totalPerformanceLossesKWh', show: true },
  { key: 'outputRatioPercent', i18nLabel: 'outputRatioPercent', show: true },
  { key: 'totalDowntimeLossesKWh', i18nLabel: 'totalDowntimeLossesKWh', show: true },
  { key: 'totalSimulatedYeldKWh', i18nLabel: 'totalSimulatedYeldKWh', show: true },
  { key: 'kWhPerKWp', i18nLabel: 'kWhPerKWp', show: true },
  { key: 'performanceLoosRate', i18nLabel: 'plr', show: true }
];

const productionAnalysisPortfolioStringsColumns = [
  { key: 'totalPerformanceLossesKWh', i18nLabel: 'totalPerformanceLossesKWh', show: true },
  { key: 'totalDowntimeLossesKWh', i18nLabel: 'totalDowntimeLossesKWh', show: true },
  { key: 'kWhPerKWp', i18nLabel: 'kWhPerKWp', show: true },
  { key: 'outputRatioPercent', i18nLabel: 'outputRatioPercent', show: true },
  { key: 'zip', i18nLabel: 'zip', show: true },
  { key: 'performanceLoosRate', i18nLabel: 'plrPerString', show: true },
  { key: 'totalSimulatedYeldKWh', i18nLabel: 'totalSimulatedYeldKWhPerString', show: true },
  { key: 'totalYieldKWh', i18nLabel: 'totalYieldKWhPerString', show: true }
];

const getDefaultColumns = (roleType) => [
  { key: 'sm_id', i18nLabel: 'SMID', show: true },
  { key: 'city', i18nLabel: 'enableCity', show: true },
  { key: 'plant', i18nLabel: 'editColumnsModalEnabledPlant', show: true },
  { key: 'last_login', i18nLabel: 'editColumnsModalEnabledLastLogin', show: true },
  { key: 'installationDate', i18nLabel: 'editColumnsModalEnabledInstallationDate', show: true },
  { key: 'installationFinishedDate', i18nLabel: 'enableInstallationFinishedDate', show: true },
  { key: 'lastStreamUpdate', i18nLabel: 'enableLastConnection', show: true },
  { key: 'oem', i18nLabel: 'oem', show: !['oem', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem_employee', 'oem_employee_read_only'].includes(roleType) },
  { key: 'license', i18nLabel: 'License', show: [SOLAR_MANAGER_APP_ID, HELION_APP_ID, ASKOMA_APP_ID, CKW_APP_ID, EZEE_APP_ID, THERMONDO_APP_ID, EKZ_APP_ID].includes(APP_ID) },
  { key: 'access', i18nLabel: 'access', show: ['viewer'].includes(roleType) },
  { key: 'note', i18nLabel: 'userSettingNotesLabel', show: true },
  { key: 'kWp', i18nLabel: 'kWPeakOutputLabel', show: true }
];

export const getOptionalColumnsSwitchers = (roleType, page) => {
  switch (page) {
    case 'devices':
      return getDeviceColumns(roleType);
    case 'productionAnalysisPortfolioSystems':
      return productionAnalysisPortfolioSystemsColumns;
    case 'productionAnalysisPortfolioStrings':
      return productionAnalysisPortfolioStringsColumns;
    default:
      return getDefaultColumns(roleType);
  }
};
